import { FinishedQuestions, Priorities, Scoring } from "../types/types";

export const evaluateFormResponses = (
  finishedQuestions: FinishedQuestions[],
  priorityMatrix: Priorities[],
  scoring: Scoring[]
) => {
  for (let i = 0; i < finishedQuestions.length; i++) {
    if (finishedQuestions[i].isSpecialCase) {
      let priority = priorityMatrix.find(
        (x) => x.priorityOptionId !== null && x.priorityOptionId === finishedQuestions[i].priorityOptionId
      );

      if (priority) {
        let returnObject = {
          finalScore: priority.scoringRange?.lower,
          priority: priority.priority,
          successMessage: finishedQuestions[i].customSuccessMessage
            ? finishedQuestions[i].customSuccessMessage
            : priority.successMessage,
        };
        console.log("return object: ", returnObject);
        return returnObject;
      }
    }
  }
  return calculateScore(finishedQuestions, priorityMatrix, scoring);
};

export const calculateScore = (
  finishedQuestions: FinishedQuestions[],
  priorityMatrix: Priorities[],
  scoring: Scoring[]
) => {
  let finalScore = 0;
  let priority = "P4";
  let successMessage = "";

  for (let i = 0; i < finishedQuestions.length; i++) {
    const questionId = finishedQuestions[i].questionId;
    const scoringRule = scoring.find((item) => item.questionId === questionId);
    const answer = finishedQuestions[i].answer;

    if (scoringRule) {
      switch (scoringRule?.scoringType) {
        case "comparison":
          const comparisonValue = scoringRule.answers.find((q) =>
            q.value.some((v) => v.toLowerCase().trim().includes(answer.toLowerCase().trim()))
          );
          if (comparisonValue) {
            finalScore += comparisonValue.score;
          }
          break;
        case "numberRange":
          const numericAnswer = parseInt(answer);
          if (!isNaN(numericAnswer)) {
            for (let j = 0; j < scoringRule.answers.length; j++) {
              const [lower, upper] = scoringRule.answers[j].value;
              if (numericAnswer >= lower && numericAnswer <= upper) {
                finalScore += scoringRule.answers[j].score;
                break;
              }
            }
          }
          break;
      }
    }
  }

  for (let i = 0; i < priorityMatrix.length; i++) {
    const scoringRange = priorityMatrix[i].scoringRange;
    if (finalScore >= scoringRange.lower && finalScore <= scoringRange.upper) {
      priority = priorityMatrix[i].priority;
      successMessage = priorityMatrix[i].successMessage;
    }
  }

  return {
    finalScore: finalScore,
    priority: priority,
    successMessage: successMessage,
  };
};
